<script setup lang="ts">
withDefaults(
    defineProps<{
        name: string
        type?: string
        modelValue?: string | null
        modelModifiers?: { lazy?: boolean }
    }>(),
    {
        type: 'text',
        modelValue: null,
        modelModifiers: () => ({}),
    },
)

const emit = defineEmits<{ (e: 'update:modelValue', value: string): void }>()
const onValueUpdate = (event: Event) => emit('update:modelValue', (event.target as HTMLInputElement).value)
</script>

<template>
    <input
        :id="name"
        :name="name"
        class="form-input"
        :type="type"
        :value="modelValue"
        @input="!modelModifiers?.lazy && onValueUpdate($event)"
        @change="modelModifiers?.lazy && onValueUpdate($event)"
    />
</template>
